
import Splide from "@splidejs/splide";
import GLightbox from "glightbox";
import {FloatLabels} from "@keodesign/float-labels";
import Choices from "choices.js";
import { pipedriveFormSubmit} from "./pipedrive.js";
const bootstrap = window.bootstrap;

export function init() {
	GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true
	});
	new FloatLabels(".js-float-wrap");
	addHoverToCollapse();
	setupMapClick();
	setupTestimonialSlider();
	setupNewsSlider();
	setupGallerySlider();
	setupPurchaseForm();
	setupVariant();
	setupBrochureDownloadForm();
	const pipedrive_form = document.querySelector(".pipedrive-form");
	if(pipedrive_form){
		pipedriveFormSubmit(pipedrive_form);
	}

	const myOffcanvas = document.getElementById("navbarSupportedContent");
	myOffcanvas.addEventListener("show.bs.offcanvas", () => {
		const header_wrap = document.querySelector("#header-wrap");
		header_wrap.classList.add("offcanvas-show");
		document.querySelector(".header-menu-btn-wrapper > button").innerHTML = "CLOSE";
		
	});
	myOffcanvas.addEventListener("hide.bs.offcanvas", () => {
		const header_wrap = document.querySelector("#header-wrap");
		header_wrap.classList.remove("offcanvas-show");
		document.querySelector(".header-menu-btn-wrapper > button").innerHTML = "MENU";
	});
}

export function setupPurchaseForm(){
	const buy_btn = document.querySelector(".buy-now");
	if (buy_btn){
		buy_btn.addEventListener("click", () => {
			const purchase_form = document.querySelector(".purchase-form");
			purchase_form.classList.remove("d-none");
		});
	}
}

export function setupVariant(){
	const choices = document.querySelectorAll(".js-choice");
	const variants = document.querySelectorAll(".variant-container");
	if (choices && variants){

		let variant_list = [];
		let choices_list = [];

		variants.forEach( variant =>{
			const variant_obj = {
				id: variant.dataset.id,
				value: variant.dataset.id,
				label: variant.dataset.title
			};
			variant_list.push(variant_obj);
		});

		choices.forEach(el  => {
			const choice_ints = new Choices(el, {
				choices: variant_list,
				searchEnabled: false
			});
			choices_list.push(choice_ints);

			choice_ints.passedElement.element.addEventListener("change", (value) =>{
				variants.forEach(variant =>{
					variant.classList.remove("show");
					if (variant.dataset.id == value.target.value){
						variant.classList.add("show");
						choices_list.forEach( sub_choice => {
							sub_choice.setChoiceByValue(value.target.value);
						});
						
					}
				});
			});
		});
	}
}

export function setupMapClick(){
	const map_click_list = document.querySelectorAll("#map-click, .map-click");
	if (map_click_list) {
		map_click_list.forEach((map_click) => {
			map_click.addEventListener("click", () => {
				// shield the map on mobile for easy scrolling
				map_click.style.display = "none";
			});
		});
	}
}

// export function setupInfoPackForm(){
// 	var form_submit = document.querySelector("#infopack-form-submit");
// 	var form_modal = document.querySelector("#info-pack-modal");
// 	var form_download = document.querySelector("#infopack-form-download");

// 	const download_list = document.querySelectorAll("a.download");
// 	download_list.forEach(download_btn =>{
// 		download_btn.addEventListener("click", (e) => {

// 			// someone might use this where infopack modal is not set, so let's check if the modal exist first
// 			if (form_download){
// 				form_modal.classList.remove("d-none");
// 				if(e.target.href){
// 					form_download.href = e.target.href;
// 					form_submit.classList.add("visually-hidden");
// 				}
// 			}
// 			e.preventDefault();
// 		});
// 	});

// 	var form_modal_div = document.querySelector("#info-pack-modal > div");
// 	form_modal_div.addEventListener("click", (e) => {
// 		if(e.target.classList.contains("close-this")) {
// 			form_modal.classList.add("d-none");
// 		}
// 	});

// 	form_download.addEventListener("click", (e) => {
// 		const form = document.getElementById("infopack-form");
// 		if(form.checkValidity()){
// 			form_submit.click();
// 			form_modal.classList.add("d-none");
// 		}
// 		else {
// 			e.preventDefault();
// 			form.reportValidity();
// 		}
// 	});
// }
export function setupBrochureDownloadForm(){
	
	const download_btn = document.querySelector("#brochure-download-btn");
	var form_modal = document.querySelector("#info-pack-modal");
	var form_modal_div = document.querySelector("#info-pack-modal > div");

	if(download_btn && form_modal && form_modal_div){
		download_btn.addEventListener("click", () => {
			form_modal.classList.remove("d-none");
			// e.preventDefault();
		});

		form_modal_div.addEventListener("click", (e) => {
			if(e.target.classList.contains("close-this")) {
				form_modal.classList.add("d-none");
			}
		});
	}
}


export function setupTestimonialSlider(){
	var testimonialSlider = document.querySelectorAll(".testimonial-slider");
	
	if(testimonialSlider){
		for (var i = 0; i < testimonialSlider.length; i++ ) {
			var slider = testimonialSlider[i].querySelector(".slider");
			
			new Splide( slider, {
				type   : "slide",
				rewind    : true,
				pagination: true,
				arrows    : false,
				autoplay: true,
			}).mount();
		}
	}
}


export function setupNewsSlider(){
	var gallerySlider = document.querySelectorAll(".news-slider");
	
	if(gallerySlider){
		gallerySlider.forEach(slider => {
			new Splide( slider, {
				type   : "slide",
				rewind    : true,
				pagination: false,
				arrows    : false,
				autoplay: true,
				gap: "1rem",
				padding: { left: "10%", right: "10%" },
				perPage: 3,
				start:2,
				breakpoints: {
					768: {
						start:2,
						perPage: 2,
						padding: { left: "10%", right: "10%" },
					},
					576: {
						perPage: 1,
						padding: { left: "10%", right: "10%" },
					},
				}
			}).mount();
		});
	}
}
export function setupGallerySlider(){
	var gallerySlider = document.querySelectorAll(".gallery-slider");
	
	if(gallerySlider){
		gallerySlider.forEach(slider => {
			new Splide( slider, {
				type   : "slide",
				height   : "20vw",
				rewind    : true,
				pagination: false,
				arrows    : false,
				autoplay: true,
				gap: "10px",
				autoWidth: true,
				padding: { left: "10%", right: "10%" },
				start:1,
				breakpoints: {
					2100: {
						height : "30vw",
					},
					1400: {
						height : "50vw",
					},
					768: {
						start:1,
						padding: { left: "10%", right: "10%" },
					},
					576: {
						padding: { left: "10%", right: "10%" },
					},
				}
			}).mount();
		});
	}
}
export function is_touch_enabled() {
	return ( "ontouchstart" in window ) ||  ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 );
}

export function closeAllCollapse(){
	const collapsable_nav_items = document.querySelectorAll(".collapse-menu > .nav-item .collapse");
	collapsable_nav_items.forEach(nav_item => {
		let bs_collapse = bootstrap.Collapse.getInstance(nav_item);
		bs_collapse.hide();
	
	});
}


export function addHoverToCollapse(){
	const nav_items = document.querySelectorAll(".collapse-menu .nav-item");

	if (nav_items){
		nav_items.forEach(nav_item => {
			const collapse_el = nav_item.querySelector(".collapse");
			if (collapse_el){
				let bs_collapse = bootstrap.Collapse.getInstance(collapse_el);
				if (!bs_collapse) {
					bs_collapse = new bootstrap.Collapse(collapse_el, {
						toggle: false
					});
				}
				
				if (!is_touch_enabled()){
					nav_item.addEventListener("mouseenter", (e) => {
						nav_item.classList.add("show");
						bs_collapse.show();
						e.stopPropagation();
					});
					nav_item.addEventListener("mouseleave", () => {
						closeAllCollapse();
						nav_item.classList.remove("show");
					});
					nav_item.querySelector("button").style.pointerEvents = "none";
				}
			}
		});
	
	}
}
window.addEventListener("DOMContentLoaded", init);